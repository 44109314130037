<template>
  <div class="columns ml-5 mr-5" id="top_nav">
    <div class="column is-narrow">
      <div class="columns mt-2">
        <div class="column is-narrow"></div>
        <div class="column is-narrow pull-down" id="logo_text_col">
          <a href="/">
            <strong><h1 class="title is-2">Cee Kay Admin</h1></strong>
          </a>
        </div>
      </div>
    </div>
    <div
      class="column pull-down"
      v-if="$route.name !== 'login' && $route.name !== 'register'"
    >
      <div class="columns">
        <nav class="column has-text-centered pull-down" v-if="store.isLoggedIn">
          <div class="columns">
            <div class="column">
              <router-link to="/" class="title is-4">Dashboard</router-link>
            </div>
            <div class="column">
              <div class="dropdown is-hoverable is-right">
                <div class="dropdown-trigger">
                  <a aria-haspopup="true" aria-controls="year-dropdown">
                    <router-link to="/analysis" class="title is-4">
                      Inventory Analysis
                    </router-link>
                    <span class="icon is-small">
                      <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </a>
                </div>
                <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                  <div class="dropdown-content">
                    <a class="dropdown-item">
                      <router-link to="/discontinued" class="title is-5">
                        Discontinued Items
                      </router-link>
                    </a>
                    <a class="dropdown-item">
                      <router-link to="/lowsale" class="title is-5">
                        Low Sale Items
                      </router-link>
                    </a>
                    <a class="dropdown-item">
                      <router-link to="/backorders" class="title is-5"
                        >Backorder Items</router-link
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <router-link to="/purchase_order" class="title is-4">
                Purchase Order
              </router-link>
            </div>
            <div class="column">
              <router-link to="/accounts" class="title is-4">
                Manage Accounts
              </router-link>
            </div>
            <div class="column">
              <router-link to="/vendor_settings" class="title is-4">
                Vendor Settings
              </router-link>
            </div>
            <div class="column">
              <button class="button" @click="logout()">Log Out</button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: "Cee Kay Admin Dashboard",
};
</script>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { store, logoutSession } from "../store";
import axios from "axios";

const router = useRouter();

async function logout() {
  await axios.get("api/authentication/logout");
  logoutSession();
  router.push({
    name: "home",
  });
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#top_nav {
  padding-bottom: 3em;
}

#logo_text_col {
  margin-left: -3em;
}

.router-link-active {
  color: #4169e1;
}

.pull-down {
  margin-top: auto;
}

.pull-centre {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
